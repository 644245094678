/*--------------------------------------------------------------
# Index Page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Animated Hero Section
--------------------------------------------------------------*/
.hero-animated {
  width: 100%;
  min-height: 50vh;
  background-size: cover;
  position: relative;
  padding: 5rem 0 0.625rem;
  font-family: NeueFrutigerWorld-Light, sans-serif;
}
.max-width-layout {
  max-width: 1480px;
  margin: 0 auto;
}
.col-lg-6.col-xl-6.col-md-12.col-sm-12.col-xs-12 {
  padding-left: 5px;
}

.row {
  padding: 7px;
}
.hero-animated h2 {
  color: #0b5ed7;
  margin: 0 0 10px 0px;
  font-weight: bold;
  font-size: 4rem;
  font-family: NeueFrutigerWorld-Light, sans-serif;
  padding: 2rem 0;
}
.about .about-desc ul li {
  margin-bottom: 8px;
  display: flex;
  align-items: baseline;
}

.about .about-desc ul li:before {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  margin-right: 8px;
}
.hero-animated h2 span {
  color: var(--color-primary);
}
#register {
  padding-left: 4px;
}
.hero-animated p {
  color: var(--color-dark-redish-gray);
  margin: 0 0 30px 0;
  padding-left: 4px;
  font-size: 1.25rem;
  line-height: 1.7rem;
  letter-spacing: 1px;
  font-family: NeueFrutigerWorld-Regular, sans-serif;
}

.hero-animated .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (min-width: 992px) {
  .hero-animated .animated {
    max-width: 75%;
  }
}

@media (max-width: 991px) {
  .hero-animated .animated {
    max-width: 75%;
  }
}

@media (max-width: 575px) {
  .hero-animated .animated {
    max-width: 100%;
  }
}

.hero-animated .btn-get-started {
  font-size: 1.4rem;
  display: inline-block;
  padding: 0.4rem 2rem;
  border-radius: 2rem;
  transition: 0.5s;
  color: var(--color-blue);
  background: var(--color-white);
  font-family: NeueFrutigerWorld-Light, sans-serif;
  text-align: center;
  border: solid;
  font-weight: bolder;
}
.section-header-promise {
  margin-bottom: 4rem !important;
}
@media (max-width: 640px) {
  .section-header-promise {
    margin-bottom: 3rem !important;
  }
  .section-header-promise h2 {
    font-size: 1.5rem;
  }
}
.img-heart-safety {
  max-width: 90% !important;
}

.section-header {
  margin-top: 3rem !important;
}
@media (max-width: 640px) {
  .section-header-promise {
    margin-top: 1rem !important;
  }
}
.tab-content-journey {
  margin-top: 3rem;
}

.hero-animated .btn-get-started:hover,
.hero-animated .btn-get-started:focus {
  background: rgba(var(--color-blue-rgb), 0.8);
  color: white;
}

@media (max-width: 640px) {
  .hero-animated h2 {
    font-size: 1.8rem;
    margin-top: 1rem;
  }

  .hero-animated p {
    font-size: 1rem;
    margin-bottom: 30px;
  }
  #register {
    justify-content: center;
  }
  .hero-animated .btn-get-started {
    font-size: 0.8rem;
    width: 10rem;
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

/*--------------------------------------------------------------
# Featured Services Section
--------------------------------------------------------------*/
.featured-services .service-item {
  padding: 1rem;
  transition: all ease-in-out 0.4s;
  background: var(--color-white);
  height: 230px;
  border-radius: 1rem;
  border: 2px solid var(--color-blue);
  text-align: center;
  align-items: center;
  display: grid;
  width: 100%;
}

.featured-services .service-item .icon {
  height: 50%;
}

.featured-services .service-item h4 {
  font-weight: 600;
  margin-bottom: 15px;
  font-size: 24px;
}

.featured-services .service-item h4 a {
  color: var(--color-secondary);
  transition: ease-in-out 0.3s;
}

.featured-services .service-item p {
  line-height: 1.2rem;
  font-size: 1rem;
  height: 50%;
  color: var(--color-dark-gray);
  font-family: NeueFrutigerWorld-Medium, sans-serif;
}

.featured-services .service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0px 0 60px 0 rgba(var(--color-blue-rgb), 0.3);
}

.featured-services .service-item:hover h4 a {
  color: var(--color-primary);
}
@media (max-width: 767px) {
  .featured-services .service-item {
    height: 80px;
    display: flex;
    align-items: center;
  }
  .featured-services .service-item .icon {
    height: auto;
    text-align: center;
    width: 40px;
  }
  .featured-services .service-item p {
    height: auto;
    text-align: left;
    margin-left: 1rem;
    margin-top: 1rem;
    font-size: 0.8rem;
  }
}

/*--------------------------------------------------------------
# About Section
--------------------------------------------------------------*/
.about .about-img {
  position: relative;
  margin: 60px 0 0 60px;
}

.about .about-img:before {
  position: absolute;
  inset: -60px 0 0 -60px;
  z-index: -1;
  content: '';
  background-repeat: no-repeat;
}

@media (max-width: 575px) {
  .about .about-img {
    margin: 30px 0 0 30px;
  }

  .about .about-img:before {
    inset: -30px 0 0 -30px;
  }
}

.about h2 {
  color: var(--color-default);
  font-family: NeueFrutigerWorld-Light, sans-serif;
  margin: 0rem;
}

@media (max-width: 768px) {
  .about h2 {
    font-size: 1.5rem;
  }
}

.about .nav-pills {
  justify-content: center;
  margin-top: 2rem;
}

.about .nav-pills li + li {
  margin-left: 40px;
}

.about .nav-link {
  background: none;
  font-size: 1.56rem;
  font-weight: 400;
  color: var(--color-black);
  padding: 1rem;
  margin-bottom: -2px;
  border-radius: 0;
  font-family: NeueFrutigerWorld-Regular, sans-serif;
}

.about .nav-link.active {
  color: var(--color-blue);
  background: none;
  border-bottom: 3px solid var(--color-blue);
  box-shadow: -5px -41px 14px -36px rgba(var(--color-blue-rgb), 0.4) inset;
}

@media (max-width: 575px) {
  .about .nav-link {
    font-size: 1rem;
  }
}

.about .tab-content h4 {
  font-size: 18px;
  margin: 0;
  font-weight: 700;
  color: rgba(var(--color-default-rgb), 0.6);
}

.about .tab-content i {
  font-size: 22px;
  line-height: 0;
  margin-right: 8px;
  color: var(--color-primary);
}
.about #profiler .about-desc {
  color: var(--color-white);
  background-color: var(--color-blue);
  font-size: 1.25rem;
  font-family: NeueFrutigerWorld-Regular, sans-serif;
}
.about #signature .about-desc {
  color: var(--color-white);
  background-color: var(--color-orange);
  font-size: 1.25rem;
  font-family: NeueFrutigerWorld-Regular, sans-serif;
}
.about .about-desc h2 {
  color: var(--color-white) !important;
  font-family: NeueFrutigerWorld-Bold, sans-serif;
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
.about .about-desc ul li {
  line-height: 2rem;
}
.about .btn-learn {
  border: 2px solid var(--color-white);
  border-radius: 2rem;
  color: var(--color-white);
  padding: 0.5rem 2rem;
  margin: 0 auto;
  text-align: center;
  font-family: NeueFrutigerWorld-Light, sans-serif;
  font-size: 1.5rem;
  font-weight: bolder;
}
.about .btn-learn:hover,
.about .btn-learn:focus:hover {
  background: var(--color-white);
  color: var(--color-blue);
}
.about #signature .btn-learn:hover,
.about #signature .btn-learn:focus:hover {
  background: var(--color-white);
  color: var(--color-orange);
}
@media (max-width: 768px) {
  .about .about-desc h2 {
    font-size: 1.5rem;
  }
  .about .about-desc p {
    font-size: 1rem;
  }
  .about .about-desc ul {
    font-size: 1rem;
  }
  .about .btn-learn {
    font-size: 0.8rem;
    max-width: 80vw;
  }
}

.disclaimer {
  font-size: 1rem;
  color: var(--color-dark--gray);
  font-family: NeueFrutigerWorld-Regular, sans-serif;
  margin: 1rem 1rem 1.5rem 1rem;
}
@media (max-width: 575px) {
  .disclaimer {
    font-size: 0.875rem;
  }
}
