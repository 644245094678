#footer {
  background-color: var(--color-blue);
  color: var(--color-white);
  padding: 2rem 1rem;
}
#footer h3 {
  font-size: 1rem;
  font-family: NeueFrutigerWorld-Bold, sans-serif;
  margin: 0;
  padding: 0;
}
.philips-logo-icon{
  padding-left: 36px;
}
#footer a {
  color: var(--color-white);
  text-decoration: none;
  font-size: 1rem;
  display: block;
  margin: 0.2rem 0;
  padding: 0;
  font-family: NeueFrutigerWorld-Regular, sans-serif;
  cursor: pointer;
}
#footer a::hover,
#footer a::focus {
  color: var(--color-white);
  text-decoration: underline;
}
@media (max-width: 575px) {
  #footer .philips-logo-icon {
    margin-bottom: 2rem;
  }
}
