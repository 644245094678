@import url(./assets/css/variables.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
:root {
  scroll-behavior: smooth;
}

a {
  color: var(--color-links);
  text-decoration: none;
}

a:hover {
  color: var(--color-links-hover);
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-primary);
}

h2 {
  font-size: 2.8rem;
}
@media (max-width: 768px) {
  h2 {
    font-size: 1.5rem;
  }
}

@font-face {
  font-family: NeueFrutigerWorld-Black;
  src: local('NeueFrutigerWorld-Black'),
    url('assets/fonts/NeueFrutigerWorldW05-Black.woff');
  font-display: swap;
}
@font-face {
  font-family: NeueFrutigerWorld-Bold;
  src: local('NeueFrutigerWorld-Bold'),
    url('assets/fonts/NeueFrutigerWorldW05-Bold.woff');
  font-display: swap;
}
@font-face {
  font-family: NeueFrutigerWorld-Book;
  src: local('NeueFrutigerWorld-Book'),
    url('assets/fonts/NeueFrutigerWorldW05-Book.woff');
  font-display: swap;
}
@font-face {
  font-family: NeueFrutigerWorld-Heavy;
  src: local('NeueFrutigerWorld-Heavy'),
    url('assets/fonts/NeueFrutigerWorldW05-Heavy.woff');
  font-display: swap;
}
@font-face {
  font-family: NeueFrutigerWorld-Light;
  src: local('NeueFrutigerWorld-Light'),
    url('assets/fonts/NeueFrutigerWorldW05-Light.woff');
  font-display: swap;
}
@font-face {
  font-family: NeueFrutigerWorld-Medium;
  src: local('NeueFrutigerWorld-Medium'),
    url('assets/fonts/NeueFrutigerWorldW05-Medium.woff');
  font-display: swap;
}
@font-face {
  font-family: NeueFrutigerWorld-Regular;
  src: local('NeueFrutigerWorld-Regular'),
    url('assets/fonts/NeueFrutigerWorldW05-Regular.woff');
  font-display: swap;
}
@font-face {
  font-family: NeueFrutigerWorld-Thin;
  src: local('NeueFrutigerWorld-Thin'),
    url('assets/fonts/NeueFrutigerWorldW05-Thin.woff');
  font-display: swap;
}
