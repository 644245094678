#timelineMobileProfiler {
  transform: rotate(-90deg);
  height: 100vw;
  overflow: auto;
  max-width: 380px;
  direction: rtl;
}
#timelineMobileProfiler .timelineMobile {
  direction: ltr;
  margin-right: 2rem;
}
#timelineMobileProfiler .timeline-item {
  min-height: 250px;
}
#timelineMobileProfiler .numbers-icon {
  height: 1rem;
  width: 1rem;
  transform: rotate(90deg);
}
#timelineMobileProfiler .timeline-dot {
  margin: 0rem;
  padding: 0.5rem;
  border-width: 5px;
  border-color: var(--color-blue);
}
#timelineMobileProfiler .timeline-connector {
  width: 3px;
  background-color: var(--color-blue);
  flex-grow: 2;
}
#timelineMobileProfiler .timeline-opposite-content {
  transform: rotate(90deg);
  padding: 1rem 2rem !important;
}
#timelineMobileProfiler .timeline-opposite-content h3 {
  color: var(--color-blue);
  text-align: left;
  font-size: 1.4rem;
  font-weight: 600;
  padding-top: 2rem;
  font-family: NeueFrutigerWorld-Bold, sans-serif;
}

#timelineMobileProfiler .timeline-opposite-content p {
  color: var(--color-dark-redish-gray);
  text-align: left;
  font-size: 1.2rem;
  margin: 0rem;
  font-family: NeueFrutigerWorld-Regular, sans-serif;
}
#timelineMobileProfiler .timeline-content {
  display: none;
}

@media (min-width: 575px) {
  #timelineMobileProfiler {
    margin: 0rem;
    display: none;
  }
}
