#privacyModal .modal-body {
  max-height: 85vh;
  overflow-y: auto;
  color: var(--color-default);
  font-family: NeueFrutigerWorld-Regular, sans-serif;
}
#privacyModal .modal-dialog {
  max-width: 1200px;
}
#privacyModal .modal-body h2 {
  color: var(--color-default);
  font-family: NeueFrutigerWorld-Bold, sans-serif;
  text-align: center;
  margin-bottom: 1rem;
}
