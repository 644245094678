#sliderHeartPrint {
  margin: 3rem 0rem;
}
#sliderHeartPrint .sliderHeartPrint-header {
  margin: 0rem 3rem;
  align-items: center;
  font-family: NeueFrutigerWorld-Light, sans-serif;
  text-align: center;
}
#sliderHeartPrint .swiper-container {
  margin-top: 3rem;
  position: relative;
  background: #f2f6ff;
  padding: 2rem;
  margin-left: 0px;
  margin-right: 0px;
}

#sliderHeartPrint.swiper-pagination {
  bottom: 10px !important; 
  left: 0;
  width: 100%;
  text-align: center;
}

#sliderHeartPrint.swiper-pagination-bullet {
  margin: 0 5px !important; 
}

#sliderHeartPrint.slider-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px; /* Adjust this value as needed */
}

#sliderHeartPrint.slider-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

#sliderHeartPrint.slider-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  
}
#ClinicalGuidance{
  width: 65% !important;
}
#heartPrintMobile{
  width: 40% !important;
}
@media (max-width: 768px) {
  #sliderHeartPrint .slider-container {
    flex-direction: column;
  }
  #sliderHeartPrint.slider-image, .slider-content {
    text-align: center;
  }
  #sliderHeartPrint.swiper-pagination {
    position: static;
    margin-top:1.25rem;
  }
}

#sliderHeartPrint .slider-container .slider-content h2 {
  color: var(--color-blue);
  margin-bottom: 1rem;
  font-family: NeueFrutigerWorld-Medium, sans-serif;
}
#sliderHeartPrint .slider-container .slider-content p {
  color: var(--color-dark-redish-gray);
  margin-bottom: 1rem;
  font-size: 1.25rem;
  padding-right: 1.875rem;
  font-family: NeueFrutigerWorld-Regular, sans-serif;
}
#sliderHeartPrint .slider-container .slider-image {
  display: flex;
  justify-content: center;
}
@media (max-width: 768px) {
  #sliderHeartPrint .slider-container .slider-content p {
    font-size: 1rem;
  }
  #sliderHeartPrint .slider-container .slider-content {
    padding-top: 1rem;
  }
  #sliderHeartPrint .sliderHeartPrint-header {
    font-size: 1.5rem;
  }
  #sliderHeartPrint .slider-container .slider-content h2 {
    font-size: 1.5rem;
  }
  .swiper-button-next,
  .swiper-button-next {
    display: none;
  }
  .swiper .swiper-button-next,
  .swiper .swiper-button-prev {
    display: none;
  }
}
