#services {
  margin-top: 6rem;
}
#services .journey-info-container h2 {
  font-family: NeueFrutigerWorld-Medium, sans-serif;
  margin-bottom: 2rem;
}
#services #about {
  margin: 3rem 0rem;
}
.max-width-layout {
  max-width: 1920px;
  margin: 0 auto;
}
#img1{
  width: 70%;
}

#services .journey-info-container {
  margin: 0 4rem 3rem 4rem;
}

#services .journey-info-container p {
  font-size: 1.25rem;
  color: var(--color-dark-redish-gray);
  font-family: NeueFrutigerWorld-Regular, sans-serif;
}
#information .about-desc {
  color: var(--color-white);
  background-color: var(--color-sky-blue);
  padding: 2rem 4rem;
}
.two-columns {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
#information .col-xl-6,
#information .col-lg-6,
#information .col-md-6,
#information .col-sm-12,
#information .col-12 {
    height: auto; 
}

#information .img-fluid {
    max-width: 100%;
    height: auto;
    object-fit: cover;
}
.two-columns li {
  width: 50%;
  box-sizing: border-box;
  padding: 5px; 
}
#services .star-icon {
  width: 4rem;
  height: auto;
  margin-bottom: 1rem;
}
.small-heading {
  font-size: 2.5rem; 
}
#services #information .about-desc h3 {
  font-family: NeueFrutigerWorld-Bold, sans-serif;
  font-size: 2.18rem;
}
#services #information .about-desc p {
  font-family: NeueFrutigerWorld-Medium, sans-serif;
  font-size: 1.5rem;
}
#services #information .about-desc li {
  font-family: NeueFrutigerWorld-Regular, sans-serif;
  font-size: 1.3rem;
}

li {
  list-style-type: none; 
  padding-left: 15px;
  
}
.multi-column-list {
    display: flex;
    flex-wrap: wrap; 
    list-style: none; 
    padding-left: 0; 
}

#services #timeline{
  margin-top: -30px;
}
.multi-column-list li {
    width: 50%; 
    box-sizing: border-box; 
    padding: 5px 10px; 
}


#services .information-points {
  margin-top: 3rem;
 
}

#services #timeline h2 {
  text-align: center;
}
#services .information-points li {
  font-size: 1rem;
  font-family: NeueFrutigerWorld-Regular, sans-serif;
}
#services #beats {
  margin: 3rem 4rem;
}
@media (max-width: 575px) {
  #services .journey-info-container {
    margin: 0 1rem 2rem 1rem;
  }
  #information .about-desc {
    padding: 2rem 1.5rem;
  }
  #services #beats {
    margin: 3rem 1rem 1rem 1rem;
  }

  #services .information-points li,
  #services #beats .beats-content p,
  #services .journey-info-container p {
    font-size: 1rem !important;
  }
  #services #timeline h2 {
    text-align: center;
  }
}
#services #timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#services #beats .beats-image {
  padding: 4rem 0rem;
  text-align: center;
}
#services #beats .beats-content {
  padding: 4rem;
  color: var(--color-dark-redish-gray);
}
#services #beats .beats-content h3 {
  color: var(--color-black);
  margin-bottom: 1rem;
  font-family: NeueFrutigerWorld-Regular, sans-serif;
  font-size: 2.18rem;
}
#services #beats .beats-content p {
  color: var(--color-dark-redish-gray);
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-family: NeueFrutigerWorld-Regular, sans-serif;
}
#services #beats .beats-content li {
  color: var(--color-dark-redish-gray);
  font-size: 1.25rem;
  font-family: NeueFrutigerWorld-Regular, sans-serif;
}
#services .btn-try-now {
  font-size: 1.2rem;
  display: inline-block;
  padding: 0.4rem 2rem;
  border-radius: 2rem;
  transition: 0.5s;
  color: var(--color-blue);
  background: var(--color-white);
  font-family: NeueFrutigerWorld-Light, sans-serif;
  text-align: center;
  border: solid;
  min-width: 10rem;
  font-weight: bolder;
}
#services .btn-try-now:hover,
#services .btn-try-now:focus {
  background: rgba(var(--color-blue-rgb), 0.8);
  color: white;
}
#services .nav-link .profilerTab {
  background-image: url('../../assets/images/profilerTab.png');
  height: 93px;
  width: 78px;
}
.signatureTab {
  margin-left: 20px;
}
.information-points .d-flex {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center; 
}

#services .nav-link.active .profilerTab {
  background-image: url('../../assets/images/profilerTabActive.png') !important;
}
#services .nav-link .signatureTab {
  background-image: url('../../assets/images/signatureTab.png');
  height: 93px;
  width: 78px;
}
#services .nav-link.active .signatureTab {
  background-image: url('../../assets/images/signatureTabActive.png') !important;
}
@media (max-width: 768px) {
  
  #services #beats .beats-image {
    padding: 1rem;
  }
  #services #beats .beats-image img {
    width: 80% !important;
  }
  #services #beats .beats-content {
    padding: 1rem;
  }
  #services #information .about-desc h3,
  #services #beats .beats-content h3 {
    font-size: 1.5rem;
  }
  #services #information .about-desc p,
  #services #information .about-desc li,
  #services #beats .beats-content li {
    font-size: 1rem;
  }
  #services .btn-try-now {
    font-size: 0.8rem;
  }
  #try-now{
    justify-content: center;
  }
}
