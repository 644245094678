/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  padding: 0 1rem;
  transition: all 0.5s;
  z-index: 997;
  background-color: var(--color-blue);
  height: 54px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

#myList {
  margin-right: 70%;
  margin-bottom: 10px;
}

.header.sticked {
  background: var(--color-white);
  box-shadow: 0px 2px 20px rgba(var(--color-secondary-rgb), 0.1);
}

.header .logo img {
  margin-left: 42px;
  height: 68px;
  margin-bottom: 27%;
  
}
@media (max-width: 575px) {
  .header .logo img {
    margin-left: -1rem;
  }
}

.header .logo {
  width: 215px;
}

.header .btn-getstarted,
.header .btn-getstarted:focus {
  font-size: 1rem;
  color: var(--color-white);
  padding: 0.2rem 0.7rem;
  border-radius: 2rem;
  transition: 0.3s;
  font-family: NeueFrutigerWorld-Light, sans-serif;
  border: 1.5px solid var(--color-white);
  margin-bottom: 4.4rem;
  position: absolute;
  right: 4rem;
  font-weight: bolder;
}

.header .btn-getstarted:hover,
.header .btn-getstarted:focus:hover {
  background: var(--color-white);
  color: var(--color-blue);
}

@media (max-width: 1279px) {
  .header .btn-getstarted,
  .header .btn-getstarted:focus {
    margin-right: 0px;

  }
}

/*--------------------------------------------------------------
  # Desktop Navigation 
  --------------------------------------------------------------*/
@media (min-width: 1280px) {
  .navbar {
    padding: 0;
    position: relative;
  }

  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
    flex-direction: row;
  }

  .navbar li {
    position: relative;
   
  }

  .navbar > ul > li {
    white-space: nowrap;
    margin-bottom: 3rem;
  }

  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 20px;
    font-family: NeueFrutigerWorld-Regular, sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: var(--color-white);
    white-space: nowrap;
    transition: 0.3s;
    position: relative;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar > ul > li > a:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--color-white);
    visibility: hidden;
    transition: all 0.3s ease-in-out 0s;
    transform: scaleY(0);
    transition: all 0.3s ease-in-out 0s;
  }

  .navbar a:hover:before,
  .navbar li:hover > a:before,
  .navbar .active:before {
    visibility: visible;
    transform: scaleX(0.7);
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover > a {
    color: var(--color-white);
  }

  .navbar .dropdown a:hover:before,
  .navbar .dropdown:hover > a:before,
  .navbar .dropdown .active:before {
    visibility: hidden;
  }

  .navbar .dropdown a:hover,
  .navbar .dropdown .active,
  .navbar .dropdown .active:focus,
  .navbar .dropdown:hover > a {
    color: var(--color-white);
    background: var(--color-blue);
  }

  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    margin: 0;
    padding: 0 0 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: var(--color-blue);
    transition: 0.3s;
  }

  .navbar .dropdown ul li {
    min-width: 200px;
  }

  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 0.9rem;
    text-transform: none;
    font-weight: 400;
    color: rgba(var(--color-white-rgb), 0.8);
  }

  .navbar .dropdown ul a i {
    font-size: 12px;
  }

  .navbar .dropdown ul a:hover,
  .navbar .dropdown ul .active,
  .navbar .dropdown ul .active:hover,
  .navbar .dropdown ul li:hover > a {
    color: var(--color-white);
    background: rgba(var(--color-white-rgb), 0.3);
  }

  .navbar .dropdown:hover > ul {
    opacity: 1;
    visibility: visible;
  }

  .navbar .megamenu {
    position: static;
  }

  .navbar .megamenu ul {
    right: 0;
    padding: 10px;
    display: flex;
  }

  .navbar .megamenu ul li {
    flex: 1;
  }

  .navbar .megamenu ul li a,
  .navbar .megamenu ul li:hover > a {
    color: rgba(var(--color-white-rgb), 0.7);
    background: none;
  }

  .navbar .megamenu ul li a:hover,
  .navbar .megamenu ul li .active,
  .navbar .megamenu ul li .active:hover {
    color: var(--color-white);
    background: rgba(var(--color-white-rgb), 0.3);
  }

  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }

  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/*--------------------------------------------------------------
  # Mobile Navigation
  --------------------------------------------------------------*/
@media (max-width: 1279px) {
  #header .btn-getstarted {
    margin-right: 3rem;
    right: 1rem;
  }
  .navbar {
    position: fixed;
    top: 0;
    left: -100%;
    width:100%;
    bottom: 0;
    transition: right 1s ease;
    z-index: 9997;
  }

  .navbar ul {
    position: absolute;
    inset: 0;
    padding: 10px 0;
    margin: 0;
    background: rgba(var(--color-blue-rgb), 0.9);
    overflow-y: auto;
    transition: 0.3s;
    z-index: 9998;
    flex-direction: column;
  }
.navbar li{
  margin-top: 10px;
}
  .navbar a,
  .navbar a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(var(--color-white-rgb), 0.7);
    white-space: nowrap;
    transition: 0.3s;
  }

  .navbar a i,
  .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }

  .navbar a:hover,
  .navbar .active,
  .navbar .active:focus,
  .navbar li:hover > a {
    color: var(--color-white);
  }

  .navbar .dropdown ul,
  .navbar .dropdown .dropdown ul {
    position: static;
    display: none;
    padding: 10px 0;
    margin: 10px 20px;
    transition: all 0.5s ease-in-out;
    border: 1px solid rgba(var(--color-secondary-light-rgb), 0.3);
  }

  .navbar .dropdown > .dropdown-active,
  .navbar .dropdown .dropdown > .dropdown-active {
    display: block;
  }

  .mobile-nav-toggle {
    display: block !important;
    color: var(--color-white);
    font-size: 28px;
    cursor: pointer;
    line-height: 0;
    transition: 0.5s;
    position: fixed;
    top: 10px;
    z-index: 9999;
    right: 20px;
  }

  .mobile-nav-toggle.bi-x {
    color: var(--color-white);
  }

  .mobile-nav-active {
    overflow: hidden;
    z-index: 9995;
    position: relative;
  }

  .mobile-nav-active .navbar {
    left: 72%;
   
  }

  .mobile-nav-active .navbar:before {
    content: '';
    position: fixed;
    inset: 0;
    background: rgba(var(--color-secondary-rgb), 0.8);
    z-index: 9996;
  }
}
