#timelineProfiler {
  max-width: 1100px;
  margin: 3rem 3rem 0rem 3rem;
}
#timelineProfiler .timeline-item {
  min-height: 180px;
}
#timelineProfiler .numbers-icon {
  height: 2rem;
  width: 2rem;
}

#timelineProfiler .timeline-dot {
  margin: 0rem;
  padding: 1rem;
  border-width: 3px;
  border-color: var(--color-blue);
}
#timelineProfiler .timeline-connector {
  width: 3px;
  background-color: var(--color-blue);
}
#timelineProfiler .timeline-opposite-content,
#timelineProfiler .timeline-content {
  display: flex;
  align-items: center;
}
#timelineProfiler .timeline-opposite-content h3 {
  color: var(--color-blue);
  text-align: left;
  font-size: 2rem;
  font-weight: bolder;
  font-family: NeueFrutigerWorld-Bold, sans-serif;
}
#timelineProfiler .timeline-content {
  padding-top: 0rem;
}

#timelineProfiler .timeline-content p {
  color: var(--color-dark-redish-gray);
  text-align: left;
  font-size: 1.2rem;
  padding-top: 0rem;
  padding-left: 6rem;
  margin: 0rem;
  font-family: NeueFrutigerWorld-Regular, sans-serif;
}
@media (max-width: 575px) {
  #timelineProfiler .timeline-opposite-content h3 {
    font-size: 1.2rem;
  }
  #timelineProfiler {
    margin: 0rem;
    display: none;
  }
  #timelineProfiler .timeline-content p {
    font-size: 0.8rem;
  }
}
