#recognition {
  margin: 4rem 4rem;
}

.recognition-image-container {
  display: flex;
  padding-top: 10px;
  gap: 2rem;
  justify-content: center;
}
.recognition-image-container .recognition-image {
  height: 12rem;
  width: auto;
}
.recognition-container p {
  font-size: 1.5rem;
  color: var(--color-dark-redish-gray);
  font-family: NeueFrutigerWorld-Regular, sans-serif;
}
@media (max-width: 768px) {
  .recognition-container p {
    font-size: 1rem;
    margin-top: 1rem;
  }

    .recognition-image-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .recognition-image {
        max-width: 50%; 
    }
}

