#carousel-doctor {
  margin-bottom: 2rem;
}
.carousel-caption {
  color: black;
}
.carousel-inner {
  width: 50%;
  margin: 0 auto;
}
.carousel-control-next {
  right: 25%;
  margin-right: -1.5rem;
}
.carousel-control-prev {
  left: 25%;
  margin-left: -1.5rem;
}

.carousel button {
  top: 55% !important;
  width: 3rem !important;
  color: var(--color-white) !important;
  height: 3rem !important;
  border-radius: 50% !important;
  background-color: var(--color-blue);
  opacity: 1 !important;
}
.carousel-item {
  border: 1px solid #eff0f6;
  border-radius: 1rem;
  padding: 3.5rem 4.5rem;
  height: 20rem;
  max-height: 20rem;
}
.carousel-item h6 {
  font-family: NeueFrutigerWorld-Regular, sans-serif;
  color: rgba(var(--color-default-rgb), 0.5);
  width: 80%;
  font-size: 1rem;
}
.carousel-item p {
  font-family: NeueFrutigerWorld-Regular, sans-serif;
  font-size: 1.125rem;
}
@media (max-width: 575px) {
  .carousel-inner {
    width: 90% !important;
  }
}
@media (max-width: 575px) {
  .carousel-item .container {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
@media (max-width: 768px) {
  .carousel-inner {
    width: 70%;
    margin: 0 auto;
  }
  .carousel-control-next {
    right: 0%;
    margin-right: 0;
  }
  .carousel-control-prev {
    left: 0%;
    margin-left: 0;
  }
  .carousel-item {
    padding: 1rem;
  }
  .carousel-item p {
    font-size: 1rem;
  }
  .carousel button {
    width: 2rem !important;
    height: 2rem !important;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 1rem;
    height: 1rem;
  }
}
